@tailwind base;
@tailwind components;
@tailwind utilities;

/* ImageSlider.css */
@keyframes slideIn {
    from {
      transform: translate(-50%, 20%);
    }
    to {
      transform: translate(0, 0);
    }
  }
  
  .img-enter {
    animation: slideIn 2s forwards;
  }

  @import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,100..900;1,100..900&display=swap');
  body { font-family: 'Asap', sans-serif; }